import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { postEndGameApi } from "../../helpers/endgameApi";
import PlayersToChooseFrom from "../../scenes/home/PlayersToChooseFrom/PlayersToChooseFrom";
import TeamToBeat from "../../scenes/home/TeamToBeat";
import { Player } from "../../types/player.interface";
import { AuthModal } from "../AuthModal";
import EndGameButton from "../EndgameButton/EndGameButton";
import Header from "../Header/Header.component";
import { PaymentModal } from "../PaymentModal";
import styles from './Play.module.scss';

const auth = getAuth();

const MAX_PRICE = 15;

interface PlayProps {
  currentUser: any;
  handleChangeUser: (newUser: any) => void;
  matchData: any;
  challengeAcceptedHandler?: (selectedPlayers: any[], user?: any) => void;
  setIsLoggingIn:(isLoggingIn: boolean) => void
}

function Play(props: PlayProps) {
  const navigate = useNavigate();
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [lineupComplete, setLineupComplete] = useState<boolean>(false);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [budgetUsed, setBudgetUsed] = useState<number>(0);
  const [isChallenging, setIsChallenging] = useState<boolean>(false);
  const [showCpuMatchTooltip, setShowCpuMatchTooltip] = useState<boolean>(false);
  const [showFreeMatchTooltip, setShowFreeMatchTooltip] = useState<boolean>(false);
  const [betAmount, setBetAmount] = useState(5);
  const [payoutAmount, setPayoutAmount] = useState(9);

  async function getAvailablePlayers() {
    try{
      const availablePlayerPool = await postEndGameApi('/available-players');
      setAvailablePlayers(availablePlayerPool?.availablePlayers);
    } catch(e) {

    }
  }

  useEffect(() => {
    if(!props.matchData?.id) {
      getAvailablePlayers();
    }
  }, [props.matchData]);

  function handleBetAmountChange (betAmount: number) {
    setBetAmount(betAmount);
    setPayoutAmount((betAmount*2)*.9);
  }

  const handlePlayerToggle = (player: Player) => {
    if(props.matchData?.userALinup?.players && JSON.parse(props.matchData?.userALinup?.players).find((x: any) => x.id === player.id)) {
      return;
    }
    // Check if the player is already selected
    const isPlayerSelected = selectedPlayers.some((p) => p.id === player.id);
    const currentPositionPlayer = selectedPlayers.find(x =>x.position === player.position);

    let newTotalPrice;

    if(isPlayerSelected) {
      newTotalPrice = selectedPlayers.reduce((total, p) => total + p.price, 0) - player.price
    } else {
      const tempSelectedPLayers = selectedPlayers;
      newTotalPrice =tempSelectedPLayers.reduce((total, p) => total + p.price, 0) + player.price - (currentPositionPlayer?.price ?? 0);
    }
    setBudgetUsed(newTotalPrice);

    // Create a new list of updated selected players
    const updatedSelectedPlayers = isPlayerSelected
      ? selectedPlayers.filter((p) => p.id !== player.id) // Remove the player
      : [...selectedPlayers, player].filter((p) => p.id !== currentPositionPlayer?.id); // Add the player, remove the current player in position if there is one

    // Update the state with the updated list
    setSelectedPlayers(updatedSelectedPlayers);
  };

  const handleChatRequest = (postAuth?: boolean, user?: any) => {
    if (selectedPlayers.length < 5) {
      alert("Select a player from each position");
      return;
    }

    if (budgetUsed>MAX_PRICE) {
      return;
    }
    // check if user is authed, if so continue. if not then open auth modal
    if(!props.currentUser?.id && !postAuth) {
      return setShowAuthModal(true);
    }

    setLineupComplete(true)
  };

  const onAuthSuccess = async (newUser: any) => {
    await props.handleChangeUser(newUser);
    setShowAuthModal(false);
    // handleChatRequest(true, newUser);

    setLineupComplete(true)
  }

  const submitLineup = (user?: any) => {
    if(props.matchData?.amount === 0 && props.currentUser.freeMatchesAvailable === 0) {
      return setShowFreeMatchTooltip(true)
    }
    if(!isChallenging) {
      if(!props?.currentUser && !user) {
        return alert('please sign in');
      }
      if((user && user.availableBalance < (props.matchData?.amount ?? betAmount)) || (props?.currentUser?.availableBalance < (props.matchData?.amount ?? betAmount))) {
        return setShowPaymentModal(true);
      }
      matchChallenge();
    }
  }

  const submitLineupAgainstCpu= (user?: any) => {
    if(props.currentUser.cpuMatchesAvailable === 0) {
      return setShowCpuMatchTooltip(true)
    }
    if(!isChallenging) {
      if(!props?.currentUser && !user) {
        return alert('please sign in');
      }
      submitCpuMatch();
    }
  }

  const depositSuccess = () => {
    setShowPaymentModal(false);
    matchChallenge();
  }

  const submitCpuMatch = async () => {
    try{
      setIsChallenging(true);
      const matchResponse = await postEndGameApi('/matches/cpu', undefined, {selectedPlayers});
  
      await props.handleChangeUser(matchResponse.updatedUser);
      navigate(`/match/${matchResponse.matchId}`);
      } catch (e) {
        console.log(`error occurred when creating match: ${e}`);
        alert('error occurred when creating match')
      }
      setIsChallenging(false);
  }

  const matchChallenge = async () => {
    if(props?.matchData?.id) {
      if(props.challengeAcceptedHandler) {
        props.challengeAcceptedHandler(selectedPlayers);
      }
    } else {
      try{
        setIsChallenging(true);
        const matchResponse = await postEndGameApi('/matches', undefined, {selectedPlayers, betAmount});
    
        await props.handleChangeUser(matchResponse.updatedUser);
        navigate(`/match/${matchResponse.matchId}`);
        } catch (e) {
          console.log(`error occurred when creating match: ${e}`);
          alert('error occurred when creating match')
        }
        setIsChallenging(false);
    }
  }

  return (
    <div className={styles.playContainer}>
      <AuthModal 
        show={showAuthModal} handleClose={() => {setShowAuthModal(false)}} 
        onAuthSuccess={(newUser: any) =>onAuthSuccess(newUser)} 
        setIsLoggingIn={(isLoggingIn: boolean) => props.setIsLoggingIn(isLoggingIn)}/>
      <PaymentModal show={showPaymentModal} 
      handleClose={() => {setShowPaymentModal(false)}} 
      headerContent={'Make a deposit to play for $. You can withdraw your $ anytime.'}
      depositSuccess={depositSuccess}
      handleChangeUser={props.handleChangeUser}/>
        <>
          {lineupComplete && (
            <>
              <Header>Your lineup is ready...</Header>
              <Header>are you?</Header>
              <TeamToBeat teamsToBeat={selectedPlayers} />
              {props.matchData ? 
                <div>
                  <p className="game-rules-fixed">Total Bet: <strong>{props.matchData.amount > 0 ? `$${props.matchData.amount}` : 'Free'}</strong></p>
                  <p className={styles.gameRulesFixedPayout}>Potential Payout: <span>${(props.matchData.amount*2)*.9}</span></p>
                </div> : 
                <div className={styles.betContainer}>
                  <Header>Place your bet:</Header>
                  <div className={styles.matchBetOptions}>
                    <Badge className={classNames(styles.matchBetOption, {[styles.selected]: betAmount === 0})} onClick={() => {handleBetAmountChange(0)}} >Free</Badge>
                    <Badge className={classNames(styles.matchBetOption, {[styles.selected]: betAmount === 1})} onClick={() => {handleBetAmountChange(1)}} >$1</Badge>
                    <Badge className={classNames(styles.matchBetOption, {[styles.selected]: betAmount === 5})} onClick={() => {handleBetAmountChange(5)}} >$5</Badge>
                    <Badge className={classNames(styles.matchBetOption, {[styles.selected]: betAmount === 20})} onClick={() => {handleBetAmountChange(20)}} >$20</Badge>
                  </div>
                  <p className={styles.gameRulesFixedPayout}>Potential Payout: <span>${payoutAmount}</span></p>
                </div>
              }
              <div className={styles.buttons}>
                <OverlayTrigger
                        placement="top"
                        overlay={showFreeMatchTooltip ? <Tooltip>One free challenge allowed per day.</Tooltip> : <></>}
                >
                  <div>
                    <EndGameButton 
                      className={styles.button}
                      text={props?.matchData?.id ? 'Let\'s Go!' : 'Submit Challenge'} 
                      disabled={isChallenging} 
                      position='relative' 
                      onClick={() => {submitLineup()}} 
                    />
                  </div>
                </OverlayTrigger>
                {!props?.matchData?.id && <p className={styles.orText}>OR</p>}
                {!props?.matchData?.id && 
                  <div>
                    <OverlayTrigger
                      placement="top"
                      overlay={showCpuMatchTooltip ? <Tooltip>One free challenge allowed per day.</Tooltip> : <></>}
                    >
                      {/* <EndGameButton className={styles.button} disabled={isChallenging} onClick={() => {submitLineupAgainstCpu()}} text="Play vs. CPU for Free" position="relative"/> */}
                      <Button className={styles.link} variant="link" disabled={isChallenging} onClick={() => {submitLineupAgainstCpu()}}>Play vs CPU For Free</Button>
                    </OverlayTrigger>
                  </div>
                }
              </div>
            </>
          )}

          {!lineupComplete && (
            <>
              <PlayersToChooseFrom
                players={availablePlayers?.length ? availablePlayers : props.matchData?.availablePlayerPool?.availablePlayers}
                handlePlayerToggle={handlePlayerToggle}
                selectedPlayers={selectedPlayers}
                maxPrice={MAX_PRICE}
                matchId={props.matchData?.id}
                claimedPlayers={props.matchData?.userALinup?.players ? JSON.parse(props.matchData?.userALinup?.players) : []}
                challengerUsername={props.matchData?.userA?.username}
              />
              <EndGameButton text={props?.matchData?.id ? 'Submit Lineup' : 'Lock In My Team'} onClick={() =>handleChatRequest(false)}/>
            </>
          )}
          <br />
        </>
      {/* <GptTextComponent
        handleResponseTextChange={handleResponseTextChange}
        selectedPlayers={selectedPlayers}
        teamToBeat={[]}
        setLineupComplete={setLineupComplete}
        currentUser={props?.currentUser}
      /> */}
    </div>
  );
}

export default Play;
