import classNames from 'classnames';
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EndGameButton from '../../../components/EndgameButton/EndGameButton';
import Header from '../../../components/Header';
import PlayerItem from '../../../components/PlayerItem';
import PositionHeader from '../../../components/PositionHeader';
import Price from '../../../components/Price';
import { Player } from '../../../types/player.interface';
import styles from './PlayersToChooseFrom.module.scss';

const positionOrder = ["PG", "SG", "SF", "PF", "C"];

interface PlayerListProps {
  players: Player[];
  handlePlayerToggle: (player: Player) => void;
  selectedPlayers: Player[];
  maxPrice: number;
  matchId?: number;
  claimedPlayers?: Player[];
  challengerUsername?: string;
  isDailyLineupChallenge?: boolean;
}

const PlayersToChooseFrom: React.FC<PlayerListProps> = ({
  players,
  handlePlayerToggle,
  selectedPlayers,
  maxPrice,
  matchId,
  claimedPlayers,
  challengerUsername,
  isDailyLineupChallenge=false,
}) => {
  const navigate = useNavigate();

  const isPlayerSelected = (player: Player) => {
    return selectedPlayers.some(
      (selectedPlayer) => selectedPlayer.id === player.id
    );
  };

  const remainingPrice =
    maxPrice - selectedPlayers.reduce((total, p) => total + p.price, 0);

  // Separate players by position
  const separatedPlayers = players?.reduce((acc, player) => {
    acc[player.position] = acc[player.position] || [];
    if(claimedPlayers && claimedPlayers.find(x => x.id === player.id)) {
      player.claimed = true;
    } else {
      player.claimed = false;
    }
    acc[player.position].push(player);
    return acc;
  }, {} as { [position: string]: Player[] });
  
  // Sort each position by price (highest to lowest)
  for (const position in separatedPlayers) {
    separatedPlayers[position].sort((a, b) => b.price - a.price);
  }


  const playerCell = (player: Player) => (
    <PlayerItem 
      player={player} 
      isSelected={isPlayerSelected(player)} 
      isDisabled={selectedPlayers.some(
        (x) => x.position === player.position
      )}
      onClick={() => handlePlayerToggle(player)}
      challengerUsername={challengerUsername}
    />
  )

  const renderPlayers = () => {
    if(separatedPlayers) {
      return positionOrder.map(position => {
        if(separatedPlayers[position]) {
          return (
            <Col xs={6} lg={4} style={{marginTop:'20px'}} key={position}>
              <PositionHeader>{position}</PositionHeader>
              {separatedPlayers[position].map(player => (
                  <div key={player.name}>
                    {player.claimed ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Player already selected by {challengerUsername}</Tooltip>}
                      >
                        {playerCell(player)}
                      </OverlayTrigger>
                    ) : (
                      <div>
                        {playerCell(player)}
                      </div>
                    )}
                  </div>
                )
              )}
            </Col>
          )
        }
        return null;
      })
    }
    return null;
  }

  return (
    <div className={styles.container}>
      { isDailyLineupChallenge ? 
        <Header>Pick your Squad</Header> :
        matchId ? 
          <Header>
            Pick your starting lineup below
          </Header>
          :
          <div className={styles.headerContainer}>
            <Header>Pick your starting lineup below</Header>
            <span>or</span>
            <EndGameButton className={styles.browseButton} onClick={() => {navigate('/matches/open')}} text="Browse open matches" position='relative'/>
          </div> 
      }
      updated
      <div className={styles.remainingBudgetContainer}>
        <h2 className={styles.remainingBudget}>Remaining Budget:  </h2>
        <Price imgClassName={styles.goldCoin} priceClassName={classNames(styles.remainingBudget, styles.price)} price={remainingPrice}/>
      </div>
      <div className={styles.playerPositionContainer}>
        <Row>
          {renderPlayers()}
        </Row>

      </div>
      {selectedPlayers.reduce((total, p) => total + p.price, 0) > maxPrice && (
        <p style={{ color: "red" }}>Maximum price exceeded!</p>
      )}
    </div>
  );
};

export default PlayersToChooseFrom;
