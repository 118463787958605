import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { endGameApi } from "../../helpers/endgameApi";
import { LeaderboardEntry } from "../../types/leaderboardEntry.interface";
import Lineup from "../Lineup";
import styles from "./DailyLeaderboard.module.scss";

interface DailyChallengeLeaderboardProps {
  currentUser: any;
  handleChangeUser: (newUser: any) => void;
  setIsLoggingIn:(isLoggingIn: boolean) => void;
}

const DailyChallengeLeaderboard = ({
  currentUser,
  handleChangeUser,
  setIsLoggingIn
}: DailyChallengeLeaderboardProps) => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [cpuPlayers, setCpuPlayers] = useState([]);
  const [currentPot, setCurrentPot] = useState(0);
  const pot = currentPot.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  async function getCurrentPot() {
    try {
      const currentPot = await endGameApi('get', '/daily-challenge/current-pot');
      setCurrentPot(currentPot?.amount || 0);
    } catch(e) {
      console.error("GET CURRENT POT ERROR:", e);
    }
  }

  async function getCpuDailyLineup() {
    try {
      const cpuLineup = await endGameApi('get', '/daily-challenge/cpu-lineup');
      if(cpuLineup?.players) {
        setCpuPlayers(JSON.parse(cpuLineup.players));
      }
    } catch(e) {
      console.error("GET CPU DAILY LINEUP ERROR:", e);
    }
  }

  async function getLeaderboard() {
    try {
      const leaderboard = await endGameApi('get', '/daily-challenge/leaderboard');
      setLeaderboard(leaderboard);
    } catch (e) {
      console.error("GET LEADERBOARD ERROR:", e);
    }
  }

  
  useEffect(() => {
    getCpuDailyLineup();
    getLeaderboard();
    getCurrentPot();
  }, []);

  return (
    <div className={styles.container}>
      <h1>Leaderboard</h1>
      <Lineup players={cpuPlayers} />
      <p className={styles.description}>Build a lineup to beat the Dream Team and earn your spot in history. Winner takes the gold (or pot of gold in this case).</p>
      <div><span className={styles.moneyText}>{`Current Pot: ${pot}`}</span></div>
      <div className={styles.tableHeader}>
        <div className={styles.col1}>
          <h2>User</h2>
        </div>
        <div className={styles.col2}>
          <h2>Result</h2>
        </div>
        <div className={styles.col3}/>
      </div>
      <div className={styles.tableEntries}>
        {leaderboard.map((entry) => {
          return (
            <div className={styles.entry} key={entry.id}>
              <div className={styles.col1}>{entry.username}</div>
              <div className={styles.col2}>{entry.scoreDifferential}</div>
              <div className={styles.col3}><Link to={`/daily/match/${entry.matchId}`}>Game Recap</Link></div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DailyChallengeLeaderboard;