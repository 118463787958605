
import { Player } from '../../types/player.interface';
import PlayerItem from '../PlayerItem';
import PositionHeader from '../PositionHeader';
import styles from './Lineup.module.scss';

interface LineupProps {
  players: Player[];
}

const Lineup = ({players}: LineupProps) => {
  const positionOrder: Record<string, number> = { PG: 1, SG: 2, SF: 3, PF: 4, C: 5 };
  const lineup = [...players].sort((a: any, b: any) => {
    const positionA = a.position.toUpperCase() as string;
    const positionB = b.position.toUpperCase() as string;
    
    return positionOrder[positionA] - positionOrder[positionB] as number;
  });

  return (
    <div className={styles.container}>
      <div className={styles.playerCardContainer}>
        {lineup.map((player, index) => (
          <div className={styles.positionCol} key={index}>
            <PositionHeader>{player.position}</PositionHeader>
            <PlayerItem player={player} isSelected={true} className={styles.playerItem}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Lineup;