export enum CurrentUserPlayer {
  NONE = 'NONE',
  A = 'A',
  B = 'B',
}

export enum MatchStatus {
  OPEN = "open",
  SIMULATING = "simulating",
  SIMULATED = "simulated",
  CANCELED = "canceled",
  ERRORED = "errored",
}

export const DAILY_LINEUP_CHALLENGE_BUY_IN = 2;
